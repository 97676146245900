
@import "./bootstrap-cey.scss";
// @import "../../ressources/normalize";


.full-page{
    height: 100vh;
}

.timer{
    height: 310px !important;
    width: 310px !important;
}